import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import HelpMethodsSection from "../../components/helpMethodsSection";
import ImageCaptionSection from "../../components/imageCaptionSection";

const TestimonialsPage = (props) => {

  const data = useStaticQuery(graphql`
    query TestimonialsPageQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "testimonials"}}) {
        frontmatter {
          imageCaptionSection {
            subTitle
            title
            imageCaptionBackground
          }
          testimonialsImages
        }
      }
    }
  `);
  const testimonials = data.markdownRemark.frontmatter.testimonialsImages || [];
  const {title, subTitle, imageCaptionBackground} = data.markdownRemark.frontmatter.imageCaptionSection;

  return (
    <Layout activeLink="/success-stories" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>


      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`bg-gray-100 rounded-lg overflow-hidden m-6 ${
              index % 2 === 0 ? 'lg:row-span-2' : 'lg:row-span-1'
            }`}
          >
            <a href={testimonial} target="_blank" rel="noopener noreferrer">
              <img
                src={testimonial}
                alt={`Testimonial ${index}`}
                className="w-full h-auto"
              />
            </a>
          </div>
        ))}
      </div>

      <HelpMethodsSection/>
    </Layout>
  );
};

TestimonialsPage.propTypes = {};

export default TestimonialsPage;
